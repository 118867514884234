import React from 'react'
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, LeetCodeButton, MailButton } from './NavbarStyledComponent'
import { FaGithub } from 'react-icons/fa';
import { CiLinkedin } from "react-icons/ci";
import { FaEnvelope } from 'react-icons/fa';
import { Bio } from '../../data/constants';

const Navbar = () => {
  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <a href="/" style={{ display: "flex", alignItems: "center", color: "white", marginBottom: '20;', cursor: 'pointer', textDecoration: 'none' }}>
            <Span>Huzaifa Naseer</Span>
          </a>
        </NavLogo>
        <MobileIcon>
          <div style={{ margin: '10px' }}>
            <a href="https://github.com/HN026" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
              <FaGithub />
            </a>
          </div>
          <div style={{ margin: '10px' }}>
            <a href="https://www.linkedin.com/in/huzaifanaseer/" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
              <CiLinkedin />
            </a>
          </div>
          <div style={{ margin: '10px' }}>
            <a href="mailto:huzaifanaseer026@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
              <FaEnvelope />
            </a>
          </div>
        </MobileIcon>
        <NavItems>
          <NavLink href="#about">About</NavLink>
          <NavLink href='#experience'>Experience</NavLink>
          <NavLink href='#projects'>Projects</NavLink>
          <NavLink href='#education'>Education</NavLink>
          <NavLink href='#blogs'>Blogs</NavLink>
        </NavItems>
        <ButtonContainer>
          <GitHubButton href={Bio.github} target="_blank">Github</GitHubButton>
          <LeetCodeButton href={Bio.linkedin} target="_blank">LinkedIn</LeetCodeButton>
          <MailButton href={Bio.gmail} target="_blank">Email</MailButton>
        </ButtonContainer>
      </NavbarContainer>
    </Nav>
  )
}

export default Navbar