import React from 'react'
import { HeroContainer, HeroLeftContainer, HeroInnerContainer, TextLoop, Title, Span, SubTitle, ResumeButton } from './HeroStyle'
// import HeroImg from '../../images/HeroImage.jpg'
import Typewriter from 'typewriter-effect';
import { Bio } from '../../data/constants';

const HeroSection = () => {
    return (
        <div id="about">
            <HeroContainer>
                <HeroInnerContainer >
                    <HeroLeftContainer id="Left">
                        <Title>Hello, I'm <span style={{color: "#1260CC"}}>{Bio.name}</span> </Title>
                        <TextLoop>
                            I code
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>{Bio.description}</SubTitle>
                        <ResumeButton href='https://hn026.github.io'>Check My Blogs!</ResumeButton>
                    </HeroLeftContainer>

                    {/* <HeroRightContainer id="Right">

                        <Img src={HeroImg} alt="hero-image" />
                    </HeroRightContainer> */}
                </HeroInnerContainer>
            </HeroContainer>
        </div>
    )
}

export default HeroSection