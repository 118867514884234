import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, CardContainer, ToggleButtonGroup, ToggleButton, Divider, ShowMoreButton } from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'
import { projects } from '../../data/constants'


const Projects = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState('all');
  const [showCount, setShowCount] = useState(6);
  const [buttonText, setButtonText] = useState("Show More");

  const handleButtonClick = () => {
    if (buttonText === "Show More") {
      setShowCount(projects.length);
      setButtonText("Show Less");
    } else {
      setShowCount(6);
      setButtonText("Show More");
    }
  };
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <ToggleButtonGroup >
          {toggle === 'all' ?
            <ToggleButton active value="all" onClick={() => setToggle('all')}>All</ToggleButton>
            :
            <ToggleButton value="all" onClick={() => setToggle('all')}>All</ToggleButton>
          }
          <Divider />
          {toggle === 'rust' ?
            <ToggleButton active value="rust" onClick={() => setToggle('rust')}>Rust</ToggleButton>
            :
            <ToggleButton value="rust" onClick={() => setToggle('rust')}>Rust</ToggleButton>
          }
          <Divider />
          {toggle === 'aiml' ?
            <ToggleButton active value="aiml" onClick={() => setToggle('aiml')}>AI&ML</ToggleButton>
            :
            <ToggleButton value="aiml" onClick={() => setToggle('aiml')}>AI&ML</ToggleButton>
          }
          <Divider />
          {toggle === 'systemprogramming' ?
            <ToggleButton active value="systemprogramming" onClick={() => setToggle('systemprogramming')}>System Programming</ToggleButton>
            :
            <ToggleButton value="systemprogramming" onClick={() => setToggle('systemprogramming')}>System Programming</ToggleButton>
          }
          <Divider />
          {toggle === 'web' ?
            <ToggleButton active value="web" onClick={() => setToggle('web')}>Web</ToggleButton>
            :
            <ToggleButton value="web" onClick={() => setToggle('web')}>Web</ToggleButton>
          }
        </ToggleButtonGroup>
        <CardContainer>
          {(toggle === 'all' ? projects : projects.filter((item) => item.category === toggle))
            .slice(0, showCount)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
        </CardContainer>
        <ShowMoreButton onClick={handleButtonClick}>{buttonText}</ShowMoreButton>
      </Wrapper>
    </Container>
  )
}

export default Projects