
import React, { useState } from 'react';
import styled from 'styled-components'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ExperienceCard from '../Cards/ExperienceCard';
import { experiences } from '../../data/constants';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    margin-top: -100px;
    padding: 40px 0px 80px 0px;
    @media (max-width: 960px) {
        padding: 0px;
    }
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1350px;
    padding: 80px 0;
    gap: 12px;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;

const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
font-style: italic;
  color: ${({ theme }) => theme.primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

const TimelineSection = styled.div`
    width: 100%;
    max-width: 1000px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding-right: 30px;
    @media (max-width: 660px) {
        width: 100%;
        align-items: center;
        padding-right: 30px;
    }
`;

const ShowMoreButton = styled.button`
  margin: 4px;
  margin-left: 2rem;
  border: 1.8px solid ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.primary};
  justify-content: center;
  display: flex;
  align-items: center;
  height: 70%;
  color: white; // white text
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 25px;
  font-weight: 500;
  text-decoration: none;
  font-size: 18px;
  transition: all 0.1s ease-in-out;
  &:hover {
    -webkit-box-shadow: -3px 4px 42px -12px rgba(14,10,255,0.48);
    -moz-box-shadow: -3px 4px 42px -12px rgba(14,10,255,0.48);
    box-shadow: -3px 4px 42px -12px rgba(14,10,255,0.48);
    scale(1.03)
}
  @media screen and (max-width: 768px) { 
    font-size: 14px;
  }
`;



const Index = () => {
    const [showCount, setShowCount] = useState(4);
    const [buttonText, setButtonText] = useState("Show More");

    const handleButtonClick = () => {
        if (buttonText === "Show More") {
            setShowCount(experiences.length); // show all experiences
            setButtonText("Show Less"); // change button text to "Show Less"
        } else {
            setShowCount(4); // show only 4 experiences
            setButtonText("Show More"); // change button text to "Show More"
        }
    };

    return (
        <Container id="experience">
            <Wrapper>
                <Title>Experience</Title>
                <TimelineSection>
                    <Timeline>
                        {experiences.slice(0, showCount).map((experience,index) => (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" color="primary" />
                                    {index !== experiences.length - 1 && <TimelineConnector style={{ background: '#1260CC' }} />}
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <ExperienceCard experience={experience}/>
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                    <ShowMoreButton onClick={handleButtonClick}>{buttonText}</ShowMoreButton>
                </TimelineSection>
            </Wrapper>
        </Container>
    )
}

export default Index