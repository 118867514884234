import React, { useState } from 'react'
import { Container, Wrapper, Title, CardContainer, Card, DateComponent, ShowMoreButton, StyledLink} from './BlogStyle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'



const Blogs = () => {
    const [numBlogs, setNumBlogs] = useState(4);
    const [showMore, setShowMore] = useState(false);

    const showMoreBlogs = () => {
        setShowMore(!showMore);
        setNumBlogs(showMore ? 4 : blogs.length);
    };
    const blogs = [
        { title: '200+ Problems On Dynamic Programming', link: 'https://hn026.github.io/posts/200DPPROB/', date: 'March 7 2024' },
    ];

    return (
        <Container id="blogs">
            <Wrapper>
                <Title>My Blogs</Title>
                <CardContainer>
                    {blogs.slice(0, numBlogs).map((blog, index) => (
                        <Card key={index}>
                            <StyledLink href={blog.link}>
                                <FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />
                                {blog.title}
                            </StyledLink>
                            <DateComponent>{blog.date}</DateComponent>
                        </Card>
                    ))}
                </CardContainer>
                <ShowMoreButton onClick={showMoreBlogs}>{showMore ? 'Show Less' : 'Show More'}</ShowMoreButton>
            </Wrapper>
        </Container>
    )
}

export default Blogs;