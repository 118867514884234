import styled from 'styled-components';

export const Container = styled.div`
    // background: linear-gradient(343.07deg, rgba(132, 59, 206, 0.06) 5.71%, rgba(132, 59, 206, 0) 64.83%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    // clip-path: polygon(0 0, 100% 0, 100% 100%,100% 98%, 0 100%);
`;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1350px;
    padding: 10px 0px 100px 0;
    gap: 12px;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;

export const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
font-style: italic;
  color: ${({ theme }) => theme.primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

export const ToggleButtonGroup = styled.div`
    display: flex;
    border: 1.5px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    font-size: 16px;
    border-radius: 12px;
    font-weight: 500;
    margin: 22px 0px;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`

export const ToggleButton = styled.div`
    padding: 8px 18px;
    border-radius: 6px;
    cursor: pointer;
    ${({ active, theme }) =>
        active && `
    background: ${theme.primary + 20};
    `
    }
    &:hover {
        background: ${({ theme }) => theme.primary + 8};
    }
    @media (max-width: 768px) {
        padding: 6px 8px;
        border-radius: 4px;
    }
`
export const Divider = styled.div`
    width: 1.5px;
    background: ${({ theme }) => theme.primary};
`


export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
    flex-wrap: wrap;
`;

export const ShowMoreButton = styled.button`
  margin-top: 15px;  
  margin: 4px;
  border: 1.8px solid ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.primary};
  justify-content: center;
  display: flex;
  align-items: center;
  height: 70%;
  color: white; // white text
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 25px;
  font-weight: 500;
  text-decoration: none;
  font-size: 18px;
  transition: all 0.1s ease-in-out;
  &:hover {
    -webkit-box-shadow: -3px 4px 42px -12px rgba(14,10,255,0.48);
    -moz-box-shadow: -3px 4px 42px -12px rgba(14,10,255,0.48);
    box-shadow: -3px 4px 42px -12px rgba(14,10,255,0.48);
    scale(1.03)
}
  @media screen and (max-width: 768px) { 
    font-size: 14px;
  }
`;
